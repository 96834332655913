<template>
  <div
    id="NftPool"
    class="nft_pool_detail art-pool-detail"
    :class="{ 'pb-0': artpoolSimpleInfo.roundNo === 4 }"
    :style="{ backgroundImage: `url(${artpoolSimpleInfo.backgroundImageUrl})` }"
  >
    <article class="header">
      <section class="left">
        <img
          v-if="artpoolSimpleInfo.bannerImagePcEnUrl && activeLanguage === 'en'"
          :src="artpoolSimpleInfo.bannerImagePcEnUrl"
        />
        <img
          v-else-if="
            artpoolSimpleInfo.bannerImagePcUrl && activeLanguage === 'ko'
          "
          :src="artpoolSimpleInfo.bannerImagePcUrl"
        />
        <img
          v-else
          :src="`/img/threespace/artpool/artpool_round${artpoolSimpleInfo.roundNo}_banner.png`"
        />
      </section>
      <section class="right">
        <section class="title-wrap">
          <p class="round">#{{ artpoolSimpleInfo.roundNo }}</p>
          <p class="title">
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              {{ artpoolSimpleInfo.title || artpoolSimpleInfo.titleEn }}
            </template>
            <template v-else>
              {{ artpoolSimpleInfo.titleEn || artpoolSimpleInfo.title }}
            </template>
          </p>
          <p class="subtitle">
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              {{ artpoolSimpleInfo.subTitle || artpoolSimpleInfo.subTitleEn }}
            </template>
            <template v-else>
              {{ artpoolSimpleInfo.subTitleEn || artpoolSimpleInfo.subTitle }}
            </template>
          </p>
        </section>

        <div class="description">
          <p class="date">
            {{
              formatPoolDates(
                artpoolSimpleInfo.poolStartDate,
                artpoolSimpleInfo.poolEndDate
              )
            }}
          </p>
          <p class="content">
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              {{
                artpoolSimpleInfo.description || artpoolSimpleInfo.descriptionEn
              }}
            </template>
            <template v-else>
              {{
                artpoolSimpleInfo.descriptionEn || artpoolSimpleInfo.description
              }}
            </template>
          </p>
        </div>
      </section>
    </article>

    <article class="nft-description">
      <section class="item artists-item">
        <p class="title">{{ $t("nftpool.participatingArtists") }}</p>
        <div class="description artists">
          <p class="artist">{{ $t("nftpool.artists") }}</p>
          <template
            v-if="
              artpoolSimpleInfo.artists != undefined &&
              artpoolSimpleInfo.artists.length > 0
            "
          >
            <div>
              <p
                class="artist-item"
                v-for="(item, i) in artpoolSimpleInfo.artists"
                :key="'artist' + i"
              >
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  {{ item.artistName || item.artistNameEn }}
                </template>
                <template v-else>
                  {{ item.artistNameEn || item.artistName }}
                </template>
              </p>
            </div>
          </template>
          <template v-else>
            <p class="artist-item">참가 중인 작가가 없습니다.</p>
          </template>
        </div>
      </section>
      <section class="item">
        <p class="title">
          {{ $t("nftpool.stakeNft") }}
        </p>
        <div class="description">
          <p class="content">
            {{ $t("nftpool.stakeNftContent") }}
          </p>
          <Button
            :onClick="onClickGoStakePage"
            :buttonClass="{ round5: artpoolSimpleInfo.roundNo === 5 }"
            :label="$t('nftpool.stakeNft')"
          />
        </div>
      </section>
      <section class="item">
        <p class="title">
          {{ $t("nftpool.vote") }}
        </p>
        <div class="description">
          <p class="content">
            {{ $t("nftpool.voteContent") }}
          </p>
          <Button
            :onClick="onClickGoVotePage"
            :buttonClass="{ round5: artpoolSimpleInfo.roundNo === 5 }"
            :label="$t('nftpool.vote')"
          />
        </div>
      </section>
    </article>

    <article class="vote vote-result">
      <section class="title-wrap">
        <p class="title">
          {{ $t("nftpool.finalVote") }}
        </p>
        <div class="subtitle">
          <p>
            <template
              v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
            >
              <template v-if="$moment().isAfter(artpoolSimpleInfo.voteEndDate)">
                {{ artpoolSimpleInfo.voteEndDate | moment("MM.DD") }}
                {{ $t("nftpool.finalVoteContentComplete") }}
              </template>
              <template v-else>
                {{ artpoolSimpleInfo.voteEndDate | moment("MM.DD") }}
                {{ $t("nftpool.finalVoteContent") }}
              </template>
            </template>
            <template v-else>
              {{ artpoolSimpleInfo.voteEndDate | moment("Do, MMM") }}
            </template>
          </p>
        </div>
      </section>
      <section class="winner">
        <article
          class="result-item"
          v-if="
            topKip !== undefined &&
            $moment().isAfter(artpoolSimpleInfo.voteEndDate)
          "
        >
          <div class="img-wrap">
            <video
              v-if="hasVideoThumbnail(topKip.winnerCollection)"
              :src="replaceVideoThumbnailUrl(topKip.winnerCollection)"
              loop
              autoplay
              :muted="true"
              @contextmenu.prevent.stop
              playsinline
            />
            <img
              v-else
              :src="replaceThumbnailUrlV2(topKip.winnerCollection)"
            />
          </div>
          <section class="description">
            <p class="title">
              <template
                v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
              >
                {{
                  topKip.winnerCollection.title ||
                  topKip.winnerCollection.titleEn
                }}
              </template>
              <template v-else>
                {{
                  topKip.winnerCollection.titleEn ||
                  topKip.winnerCollection.title
                }}
              </template>
            </p>
            <div class="nft-info">
              <div>
                <p>Artist</p>
                <p>
                  <template
                    v-if="
                      activeLanguage !== undefined && activeLanguage === 'ko'
                    "
                  >
                    @{{
                      topKip.winnerCollection.artist ||
                      topKip.winnerCollection.artistEn
                    }}
                  </template>
                  <template v-else>
                    @{{
                      topKip.winnerCollection.artistEn ||
                      topKip.winnerCollection.artist
                    }}
                  </template>
                </p>
              </div>
              <div>
                <p>Num.</p>
                <p>
                  #{{ topKip.winnerCollection.tokenId }} ({{
                    topKip.winnerCollection.nftType
                  }})
                </p>
              </div>
            </div>
          </section>

          <div class="vote-info">
            <p>VOTE :</p>
            <p>
              {{ Number(topKip.winningVote) | currency }}
            </p>
          </div>
        </article>
        <article class="result-item" v-else>
          <img src="/img/threespace/artpool/no-artwork.png" />
          <section class="description">
            <p
              class="title"
              v-if="$moment().isAfter(artpoolSimpleInfo.voteEndDate)"
            >
              {{ $t("nftpool.no1stPlace") }}
            </p>
          </section>
        </article>

        <article
          class="result-item"
          v-if="
            topErc !== undefined &&
            $moment().isAfter(artpoolSimpleInfo.voteEndDate)
          "
        >
          <div
            class="img-wrap"
            :style="{ color: artpoolSimpleInfo.secondaryColor }"
            :class="{ round5: artpoolSimpleInfo.roundNo === 5 }"
          >
            <video
              v-if="hasVideoThumbnail(topErc.winnerCollection)"
              :src="replaceVideoThumbnailUrl(topErc.winnerCollection)"
              loop
              autoplay
              :muted="true"
              @contextmenu.prevent.stop
              playsinline
            />
            <img
              v-else
              :src="replaceThumbnailUrlV2(topErc.winnerCollection)"
            />
          </div>
          <section class="description">
            <p class="title">
              <template
                v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
              >
                {{
                  topErc.winnerCollection.title ||
                  topErc.winnerCollection.titleEn
                }}
              </template>
              <template v-else>
                {{
                  topErc.winnerCollection.titleEn ||
                  topErc.winnerCollection.title
                }}
              </template>
            </p>
            <div class="nft-info">
              <div>
                <p>Artist</p>
                <p>
                  <template
                    v-if="
                      activeLanguage !== undefined && activeLanguage === 'ko'
                    "
                  >
                    @{{
                      topErc.winnerCollection.artist ||
                      topErc.winnerCollection.artistEn
                    }}
                  </template>
                  <template v-else>
                    @{{
                      topErc.winnerCollection.artistEn ||
                      topErc.winnerCollection.artist
                    }}
                  </template>
                </p>
              </div>
              <div>
                <p>Num.</p>
                <p>
                  #{{ topErc.winnerCollection.tokenId }} ({{
                    topErc.winnerCollection.nftType
                  }})
                </p>
              </div>
            </div>
          </section>
          <div class="vote-info">
            <p>VOTE :</p>
            <p>
              {{ Number(topErc.winningVote) | currency }}
            </p>
          </div>
        </article>
        <article class="result-item" v-else>
          <img src="/img/threespace/artpool/no-artwork.png" />
          <section class="description">
            <p
              class="title"
              v-if="$moment().isAfter(artpoolSimpleInfo.voteEndDate)"
            >
              {{ $t("nftpool.no1stPlace") }}
            </p>
          </section>
        </article>
      </section>
    </article>
    <article class="previous-exhibitions">
      <p class="title">PREVIOUS EXHIBITIONS</p>
      <section class="exhibition-list">
        <div class="slide-container">
          <div class="slide-wrapper">
            <template v-for="(item, i) in artpoolItems.content">
              <div
                :key="'artpool-list' + i"
                @click="onClickArtPoolListItem(item)"
                class="slide"
                :class="{ active: artpoolSimpleInfo.idx === item.idx }"
              >
                <p>#{{ item.idx }}</p>
                <div class="img_box">
                  <img :src="item.thumbnailImageUrl" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </section>
      <!-- <section class="exhibition-list">
        <div class="slide-container">
          <div class="slide-wrapper">
            <template v-for="(item, i) in artpoolItems.content">
              <div
                :key="'artpool-list' + i"
                @click="onClickArtPoolListItem(item)"
                class="slide item"
                :class="{ active: artpoolSimpleInfo.idx === item.idx }"
              >
                <div class="img_box">
                  <img :src="item.thumbnailImageUrl" />
                </div>
              </div>
            </template>
            <template v-for="(item, i) in artpoolItems.content">
              <div
                :key="'artpool-list' + i"
                @click="onClickArtPoolListItem(item)"
                class="slide item"
                :class="{ active: artpoolSimpleInfo.idx === item.idx }"
              >
                <div class="img_box">
                  <img :src="item.thumbnailImageUrl" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </section> -->
      <!-- Artpool Round4 -->
      <div
        class="nft_pool_bg_round4"
        v-if="artpoolSimpleInfo.roundNo === 4"
      ></div>
    </article>

    <!-- pool aside banner ============================= -->
    <!-- <aside
      class="pool_aside_banner detail_banner"
      :class="{ show: scrollPosition > 400 && scrollPosition < 2500 }"
    >
      <div class="top_img_box">
        <img
          :src="
            `/img/threespace/artpool/art_pool` +
            artpoolSimpleInfo.roundNo +
            `_logo.png`
          "
        />
      </div>
      <div class="bottom_img_box">
        <div
          class="vertical_border"
          :style="{
            backgroundColor: getAsideBorderColor(artpoolSimpleInfo.roundNo),
          }"
        ></div>
        <div class="date_box">
          {{ artpoolSimpleInfo.poolStartDate | moment("MM.DD") }}
          &nbsp;-&nbsp;
          {{ artpoolSimpleInfo.poolEndDate | moment("MM.DD") }}
        </div>
        <img
          src="/img/threespace/artpool/art_pool3_object.png"
          v-if="artpoolSimpleInfo.roundNo === 3"
        />
      </div>
    </aside> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Button from "@/views/threespace/components/common/Button.vue";

export default {
  name: "NftPoolDetail",
  props: {
    idxArtpool: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Button,
  },
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.artPool.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.artPool.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.artPool.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.artPool.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  data: function () {
    return {
      isBusy: false,
      perPage: 12,
      currentPage: 1,
      sort: [{ id: "roundNo", desc: "desc" }],
      externalParams: {
        keywordPoolType: "NFT",
      },
      scrollPosition: 0,
      asideBorderColor: {
        4: "#04b69d", // key=roundNo
      },
    };
  },
  computed: {
    ...mapState("artpool", [
      "artpoolItems",
      "artpoolVersionOptions",
      "artpoolSimpleInfo",
      "topRankCollections",
    ]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    topErc() {
      if (this.topRankCollections.topErc !== undefined) {
        return this.topRankCollections.topErc;
      } else {
        return undefined;
      }
    },
    topKip() {
      if (this.topRankCollections.topKip !== undefined) {
        return this.topRankCollections.topKip;
      } else {
        return undefined;
      }
    },
  },
  mounted() {
    this.requestArtpools();
    this.requestArtpool();
    this.requestArtpoolTopRank();
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions("artpool", [
      "getArtpoolSimple",
      "getArtpoolCollectionsTopRank",
    ]),
    handleScroll(e) {
      this.scrollPosition = window.scrollY;
    },
    requestArtpool() {
      this.isBusy = true;
      this.getArtpoolSimple(this.idxArtpool)
        .then(() => {
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    requestArtpoolTopRank() {
      this.isBusy = true;
      this.getArtpoolCollectionsTopRank(this.idxArtpool)
        .then(() => {
          this.isBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          this.$log.error(error);
        });
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickArtPoolListItem(artpool) {
      if (artpool.roundNo < 3) {
        this.$router.push({
          name: "NftPoolStake",
          params: { idxArtpool: artpool.idx },
        });
      } else if (artpool.roundNo >= 3) {
        this.$router.push({
          name: "NftPoolDetail",
          params: { idxArtpool: artpool.idx },
        });
      }
    },
    onClickBackBtn() {
      this.$router.push({ name: "NftPool" });
    },
    ...mapActions("artpool", ["getArtpools"]),
    requestArtpools() {
      this.$store.commit("setShowLoading", { show: true, msg: "" });
      this.isBusy = true;
      this.getArtpools(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then(() => {
          this.isBusy = false;
          this.$store.commit("setShowLoading", { show: false, msg: "" });
        })
        .catch((error) => {
          this.$log.error(error);
          this.isBusy = false;
          this.$store.commit("setShowLoading", { show: false, msg: "" });
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams,
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    },
    onClickGoStakePage() {
      if (this.$moment().isBefore(this.artpoolSimpleInfo.poolStartDate)) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.notInProgressPool"),
          show: true,
          fontSize: "sm",
        });
      } else {
        this.$router.push({
          name: "NftPoolStake",
          params: { idxArtpool: this.idxArtpool },
        });
      }
    },
    onClickGoVotePage() {
      if (this.$moment().isBefore(this.artpoolSimpleInfo.voteStartDate)) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("stake.vote.notInProgress"),
          show: true,
          fontSize: "sm",
        });
      } else {
        this.$router.push({
          name: "NftPoolVote",
          params: { idxArtpool: this.idxArtpool },
        });
      }
    },
    getAsideBorderColor(roundNo) {
      if (roundNo !== undefined && roundNo !== null && roundNo > 0) {
        const colorCode = this.asideBorderColor[roundNo];
        if (colorCode !== undefined && colorCode !== null && colorCode !== "") {
          return colorCode;
        }
        return "#000";
      }
      return "#000";
    },
    formatPoolDates(poolStartDate, poolEndDate) {
      const options = { month: "short", day: "2-digit" };
      const startDate = new Date(poolStartDate)
        .toLocaleDateString("en-US", options)
        .toUpperCase();
      const endDate = new Date(poolEndDate)
        .toLocaleDateString("en-US", options)
        .toUpperCase();
      return `${startDate} - ${endDate}`;
    },
    replaceThumbnailUrlV2(item) {
      if(item.thumbnail) {
        return item.thumbnailUrl;
      } else {
        return item.imageUrl;
      }
    },
    hasVideoThumbnail(item) {
      if(item.thumbnailOriginal) return true;
      if(item.thumbnailPreview) return true;

      return false;
    },
    replaceVideoThumbnailUrl(item) {
      if(item.thumbnailOriginal) return item.thumbnailOriginalUrl;
      if(item.thumbnailPreview) return item.thumbnailPreviewUrl;

      return this.replaceDisplayCardUrl(item);
    },
  },
  filters: {
    decimal: (value) => {
      if (!value) return 0;
      return (Math.floor((value * 100) / 10 ** 18) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currency: (value) => {
      if (!value) return 0;
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>
